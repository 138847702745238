import logo from './logo.svg';
import './App.css';
import { Button, Col, Container, Row } from 'react-bootstrap';

function App() {
  return (
    <>
      <Container fluid style={{
        background: 'linear-gradient(to bottom, rgba(162, 171, 240, .5), rgba(249, 92, 129, .5))'
      }}>
        <Row style={{
          height: '6rem'
        }} className='align-items-center'>
          <Col lg={{span: 2, offset: 1}}>
            <a style={{
              display: 'block',
              width: '100%'
            }} href='/' title='Medicinia'>
              <img style={{
                display: 'block',
                width: '100%'
              }} src={require('./logo.png')} />
            </a>
          </Col>
        </Row>
        <Row style={{
          height: '32rem'
        }} className='align-items-center text-center'>
          <Col lg={{span: 6, offset: 3}}>  
            <p>O Medicinia desenvolve projetos e plataformas para garantir o sucesso terapêutico.</p>
            <p style={{
              fontWeight: 600,
              fontSize: 32
            }}>Nossos projetos normalmente se baseiam em uma plataforma tecnológica customizada para cada situação (Medicinia) além de serviços de saúde oferecidos através da plataforma (Nani Saúde).</p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row style={{
          height: '32rem'
        }} className='align-items-center text-center'>
          <Col lg={{span: 6, offset: 3}}>  
            <p style={{
              fontWeight: 600,
              fontSize: 32
            }}>Como uma empresa de Pharma Digital Health, o Medicinia desenvolve a experiência digital para terapias tradicionais e biomarcadores digitais para terapias inovadoras e mostra as informações pertinentes e relevantes para cada stakeholder.</p>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid>
        <Row style={{
          height: '32rem'
        }} className='align-items-center text-center'>
          <Col lg={{span: 6, offset: 3}}>  
            <p>O QUE É FARMA SAÚDE DIGITAL?</p>
            <p style={{
              fontWeight: 600,
              fontSize: 32
            }}>Pharma Digital Health é essencialmente qualquer ferramenta, serviço ou estratégia digital que forneça uma troca clínica com o paciente individual (em vez de populações de pacientes):</p>
          </Col>
        </Row>
      </Container> */}
      <Container fluid style={{
        background: 'linear-gradient(to bottom, rgba(162, 171, 240, 1), rgba(249, 92, 129, 1))'
      }}>
        <Row>
          <Col style={{
            margin: '6rem 0'
          }} className='text-center'>
            <p>
              <img style={{
                width: '6rem'
              }} src={require('./people.png')} />
            </p>
            <p style={{
              marginBottom: 0,
              fontWeight: 700,
              fontSize: 32,
              color: 'white'
            }}>+ 189 mil</p>
            <p style={{
              marginBottom: 0,
              color: 'white'
            }}>Pacientes Engagados</p>
          </Col>
          <Col style={{
            margin: '6rem 0'
          }} className='text-center'>
            <p>
              <img style={{
                width: '6rem'
              }} src={require('./professional.png')} />
            </p>
            <p style={{
              marginBottom: 0,
              fontWeight: 700,
              fontSize: 32,
              color: 'white'
            }}>+ 4 mil</p>
            <p style={{
              marginBottom: 0,
              color: 'white'
            }}>Profissionais Envolvidos</p>
          </Col>
          <Col style={{
            margin: '6rem 0'
          }} className='text-center'>
            <p>
              <img style={{
                width: '6rem'
              }} src={require('./professional.png')} />
            </p>
            <p style={{
              marginBottom: 0,
              fontWeight: 700,
              fontSize: 32,
              color: 'white'
            }}>+ 70</p>
            <p style={{
              marginBottom: 0,
              color: 'white'
            }}>Instituições Impactadas</p>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{
        background: '#96377A',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        color: 'white'
      }} className='text-center'>
        <Row>
          <Col>
            © 2024 Medicinia Biomarcadores Digitais Ltda. Todos os direitos reservados.
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;